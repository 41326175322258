import { FunctionComponent, ReactElement } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { getUserRole } from '@/shared/utils';
import { RoleTypes } from '@/app/api/role/role.types';

const AdminGuard: FunctionComponent = (): ReactElement => {
	const { role } = getUserRole();

	if (role !== RoleTypes.admin) {
		return <Navigate to="/clients" />;
	}

	return <Outlet />;
};

export default AdminGuard;
