import { mergeQueryKeys } from '@lukemorales/query-key-factory';
import { clientsKeys } from './clients';
import { flowKeys } from './flows';
import { portfolioConsiderationsKeys } from './portfolio-considerations';
import { advisorsKeys } from './advisors';

export const queryKeys = mergeQueryKeys(
	clientsKeys,
	flowKeys,
	portfolioConsiderationsKeys,
	advisorsKeys
);
