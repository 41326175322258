import React from 'react';
import {
	Breadcrumbs as MuiBreadcrumbs,
	BreadcrumbsProps as MuiBreadcrumbsProps,
} from '@mui/material';
import { BreadcrumbsProps } from './breadcrumbs.types';
import { DEFAULT_ARIA_LABEL, DEFAULT_MAX_ITEMS, DEFAULT_SEPARATOR } from './breadcrumbs.constants';
import BreadcrumbsItem from './breadcrumbs-item/breadcrumbs-item.component';

const Breadcrumbs: React.FC<BreadcrumbsProps & MuiBreadcrumbsProps> = ({
	maxItems = DEFAULT_MAX_ITEMS,
	separator = <DEFAULT_SEPARATOR fontSize="small" />,
	ariaLabel = DEFAULT_ARIA_LABEL,
	items,
	...rest
}): React.ReactElement => {
	return (
		<MuiBreadcrumbs
			maxItems={maxItems}
			separator={separator}
			aria-label={ariaLabel}
			{...rest}
			className="no-print"
		>
			{items.map(({ title, path }) => (
				<BreadcrumbsItem key={title} title={title} path={path} />
			))}
		</MuiBreadcrumbs>
	);
};

export default Breadcrumbs;
