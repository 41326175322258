import { useEffect } from 'react';
import { keepPreviousData, useQuery, useQueryClient } from '@tanstack/react-query';
import { advisorsKeys } from '@/app/queries/query-keys/advisors';
import { DEFAULT_PAGE } from './advisors.constsnts';
import { AdvisorsQueryParams } from '@/app/api/advisors/advisors.types';

export const useAdvisors = ({ page, size, search }: AdvisorsQueryParams) => {
	const queryClient = useQueryClient();

	const query = useQuery({
		...advisorsKeys.list({ page, size })._ctx.search({ search }),
		placeholderData: keepPreviousData,
	});

	const { data, isPlaceholderData } = query || {};

	useEffect(() => {
		if (!data || isPlaceholderData) return;
		const { last: isLastPage } = data;

		if (!isLastPage) {
			const prefetchNextPageParams = {
				page: page ? page + 1 : DEFAULT_PAGE + 1,
				...(size && { size }),
			};

			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			queryClient.prefetchQuery({
				...advisorsKeys.list(prefetchNextPageParams)._ctx.search({ search }),
				staleTime: 5000,
			});
		}
	}, [data, page, size, search, isPlaceholderData, queryClient]);

	return query;
};
