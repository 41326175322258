import { Navigate, RouteObject } from 'react-router-dom';
import { LoginCallback } from '@okta/okta-react';
import { LoginPage } from '@/okta/login';
import OktaLayer from '@/okta/okta-layer';
import AuthGuard from './guards/auth-guard';
import { advisorRoutes } from './advisor.routes';
import AdminGuard from './guards/admin-guard';
import { adminRoutes } from './admin.routes';
import PageLayout from '@/components/layouts/layout';
import ClientsGuard from './guards/clients-guard';

export const routes: RouteObject[] = [
	{
		path: '',
		element: <OktaLayer />,
		children: [
			{
				path: '',
				index: true,
				element: <Navigate to="login" />,
			},
			{
				path: '/login',
				element: <LoginPage />,
			},
			{
				path: '/',
				element: <AuthGuard />,
				children: [
					{
						path: '',
						element: <ClientsGuard />,
						children: [
							{
								element: <PageLayout />,
								children: advisorRoutes,
							},
						],
					},
					{
						path: 'advisors/',
						element: <AdminGuard />,
						children: [
							{
								element: <PageLayout />,
								children: adminRoutes,
							},
						],
					},
				],
			},
			{
				path: 'login/callback',
				element: <LoginCallback />,
			},
		],
	},
];
