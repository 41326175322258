/* eslint-disable react/self-closing-comp */
import { FunctionComponent, ReactElement } from 'react';
import './backdrop.scss';

const Backdrop: FunctionComponent = (): ReactElement => (
	<div className="overlay">
		<div className="overlay-bg"></div>
		<div className="overlay-content">
			<div className="spinner" role="status"></div>
		</div>
	</div>
);

export default Backdrop;
