/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { FunctionComponent, ReactElement, useMemo, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { AutocompleteInputChangeReason, Autocomplete as MuiAutocomplete } from '@mui/material';
import AutocompleteItem from './autocomplete-item';
import AutocompleteGroup from './autocomplete-group';
import { AutocompleteProps, AutocompleteItem as AutocompleteItemType } from './autocomplete.types';
import './autocomplete.scss';

const Autocomplete: FunctionComponent<AutocompleteProps> = ({
	data,
	sortBy = 'group',
	placeholder,
	value,
	id,
	isFetching,
	isLastPage,
	onSelect,
	onInputChange,
	onLoadMore,
}): ReactElement => {
	const [internalValue, setInternalValue] = useState<
		(AutocompleteItemType & { groupLabel?: string; id?: string }) | null | undefined
	>(value);

	useEffect(() => {
		if (value?.label !== internalValue?.label) {
			setInternalValue(value);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value]);

	const options = useMemo(
		() =>
			data
				.map((o) => ({
					...o,
					groupLabel: o[sortBy].toLowerCase(),
				}))
				.sort((a, b) => (a[sortBy] < b[sortBy] ? -1 : 1)),
		[data, sortBy]
	);
	const defaultValue = useMemo(() => ({ label: '', group: '', groupLabel: '', id: '' }), []);

	return (
		<MuiAutocomplete
			options={options}
			id={id}
			filterOptions={(x) => x}
			groupBy={(option) => (option.groupLabel ? option.groupLabel : option.group)}
			getOptionLabel={(option) => option.label}
			sx={{ width: '100%', minWidth: '150px' }}
			size="small"
			loading={isFetching}
			value={internalValue?.label ? internalValue : defaultValue}
			isOptionEqualToValue={(option, value) => option.label === value.label}
			renderInput={(params) => <TextField {...params} placeholder={placeholder} />}
			renderOption={(props, option, _, ownerState) => {
				return (
					<AutocompleteItem
						key={`${props.key}-${option.id}`}
						props={props}
						options={options}
						option={option}
						ownerState={ownerState}
						isFetching={isFetching}
						isLastPage={isLastPage}
						onLoadMore={onLoadMore}
					/>
				);
			}}
			renderGroup={(params) => <AutocompleteGroup key={params.key} params={params} />}
			onChange={(_, newVal: any) => {
				setInternalValue(
					newVal as (AutocompleteItemType & { groupLabel?: string; id?: string }) | null
				);
				onSelect(newVal);
			}}
			onInputChange={(_, val: string, reason: AutocompleteInputChangeReason) => {
				if (reason === 'input' || reason === 'clear') onInputChange(val);
			}}
		/>
	);
};

export default Autocomplete;
