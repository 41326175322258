/* eslint-disable @typescript-eslint/no-misused-promises */
import React from 'react';
import { Link } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import { Drawer, List, Stack, Box, styled, StackProps } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import ConcurrentLogo from '@/assets/logos/concurrent-logo.png';
import SidebarActionItem from './sidebar-items/sidebar-action-item.component';
import SidebarNavItem from './sidebar-items/sidebar-nav-item.component';
import { SidebarNavItemProps } from './sidebar.types';
import { DEFAULT_DRAWER_WIDTH } from './sidebar.constants';

interface SidebarProps {
	width?: number;
	items: SidebarNavItemProps[];
}

const LogoContainer = styled(Box)(() => ({
	padding: '1.5rem 1rem 2rem 1rem',
}));
const NavContainer = styled(Stack)<StackProps>(() => ({
	height: '100%',
}));
const SideItemsContainer = styled(Stack)<StackProps>(() => ({
	height: '100%',
}));

const Sidebar: React.FC<SidebarProps> = ({
	width = DEFAULT_DRAWER_WIDTH,
	items,
}): React.ReactElement => {
	const { oktaAuth } = useOktaAuth();

	const logout = async () => {
		localStorage.clear();
		await oktaAuth.signOut();
	};

	return (
		<Drawer
			variant="permanent"
			anchor="left"
			role="navigation"
			aria-label="Main navigation"
			className="no-print"
		>
			<NavContainer component="nav" direction="column" width={width}>
				<LogoContainer>
					<Link to="/">
						<img src={ConcurrentLogo} width={146} height={44} alt="Logo" />
					</Link>
				</LogoContainer>

				<SideItemsContainer direction="column" justifyContent="space-between">
					<List>
						{items.map((item) => (
							<SidebarNavItem key={item.title} {...item} />
						))}
					</List>
					<List>
						<SidebarActionItem title="Logout" icon={<LogoutIcon />} onClick={() => logout()} />
					</List>
				</SideItemsContainer>
			</NavContainer>
		</Drawer>
	);
};

export default Sidebar;
