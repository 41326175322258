import { FunctionComponent, ReactElement, ReactNode, useMemo } from 'react';
import { Outlet, useOutletContext, useParams } from 'react-router-dom';
import { Box, Stack } from '@mui/material';
import {
	LaunchOutlined as LaunchIcon,
	ContentCopyOutlined as ContentCopyIcon,
	PrintOutlined as Print,
} from '@mui/icons-material';
import { ToggleGroupNav } from '@/components/ui/tabs';
import { Button, ButtonLink } from '@/components/ui/buttons';
import { copyLink } from '@/shared/utils';
import { useCurrentTab } from '@/shared/hooks/use-current-tab';
import { DialogueContext } from '../dialogues.types';
import { DialoguesEmptyState } from '../components/empty-state';
import { TILE_SELECTION_TAB, AI_OUTPUT_TAB, TAB_ROUTES } from './slow-brain.constants';
import {
	getClientPortalSlowBrainOutputUrl,
	getClientPortalSlowBrainTileSelectionUrl,
} from './slow-brain.utils';
import { getToggleGroupRoute } from '../dialogues.utils';

const SlowBrainPage: FunctionComponent = (): ReactElement => {
	const { client, settings, activityLog } = useOutletContext<DialogueContext>();
	const { advisorId } = useParams();
	const routes = TAB_ROUTES.map((route) => (advisorId ? `/advisors/:advisorId${route}` : route));
	const currentTab = useCurrentTab(routes) || routes[0];

	const hasSlowBrainFlowStarted = useMemo(
		() => Boolean(activityLog.find((log) => log.flow === 'slow_brain')),
		[activityLog]
	);
	const isSlowBrainFlowDisabled = useMemo(
		() => !settings.flows.find(({ flowName }) => flowName === 'slow_brain'),
		[settings]
	);

	const clientPortalLinks = useMemo(
		() => ({
			[getToggleGroupRoute(advisorId, TILE_SELECTION_TAB.route)]:
				getClientPortalSlowBrainTileSelectionUrl(client),
			[getToggleGroupRoute(advisorId, AI_OUTPUT_TAB.route)]:
				getClientPortalSlowBrainOutputUrl(client),
		}),
		[advisorId, client]
	);
	const clientPortalLink = useMemo(
		() => clientPortalLinks[currentTab],
		[clientPortalLinks, currentTab]
	);

	const emptyContainer = (children: ReactNode) => (
		<Box py="3rem" px="2.5rem">
			{children}
		</Box>
	);

	const slowBrainNotStarted = emptyContainer(
		<DialoguesEmptyState
			title="Slow Brain not started yet"
			text="Once the Slow Brain exercise is initiated by the client, activities will populate in this section."
			callToAction={
				<ButtonLink
					variant="outlined"
					endIcon={<LaunchIcon />}
					to={clientPortalLink}
					target="_blank"
					rel="noopener noreferrer"
				>
					Open Slow Brain
				</ButtonLink>
			}
		/>
	);

	const slowBrainDisabled = emptyContainer(
		<DialoguesEmptyState
			title="Slow Brain is disabled"
			text="If you’d like to enable this section go back to Overview to change settings. "
		/>
	);

	const slowBrainOutlet = (
		<Box>
			<Stack direction="row" justifyContent="space-between" my="2rem">
				<ToggleGroupNav
					ariaLabel="Slow Brain navigation"
					tabs={[TILE_SELECTION_TAB, AI_OUTPUT_TAB]}
				/>
				<Stack direction="row" gap="1rem">
					{currentTab === AI_OUTPUT_TAB.route && (
						<Button
							variant="outlined"
							endIcon={<Print />}
							onClick={() => window.print()}
							className="no-print"
						>
							Save as PDF
						</Button>
					)}
					<ButtonLink
						variant="outlined"
						endIcon={<LaunchIcon />}
						to={clientPortalLink}
						target="_blank"
						rel="noopener noreferrer"
					>
						Open Slow Brain
					</ButtonLink>
					<Button
						variant="outlined"
						endIcon={<ContentCopyIcon />}
						onClick={() => copyLink(clientPortalLink)}
						className="no-print"
					>
						Copy Link
					</Button>
				</Stack>
			</Stack>
			<Outlet context={{ settings, client, activityLog } satisfies DialogueContext} />
		</Box>
	);

	const renderSlowBrainContent = (): ReactNode => {
		if (isSlowBrainFlowDisabled) return slowBrainDisabled;
		if (!hasSlowBrainFlowStarted) return slowBrainNotStarted;
		return slowBrainOutlet;
	};

	return <Stack direction="column">{renderSlowBrainContent()}</Stack>;
};

export default SlowBrainPage;
