import { FunctionComponent, ReactElement } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

const SingleAdvisorGuard: FunctionComponent = (): ReactElement => {
	const location = useLocation();

	const hasExampleRoute = location.pathname.includes('/clients');

	if (!hasExampleRoute) {
		return <Navigate to="/advisors" />;
	}

	return <Outlet />;
};

export default SingleAdvisorGuard;
