import { useEffect } from 'react';
import { keepPreviousData, useQuery, useQueryClient } from '@tanstack/react-query';
import { ClientsQueryParams } from '@/app/api/clients/clients.types';
import { clientsKeys } from '@/app/queries/query-keys/clients';
import { DEFAULT_PAGE } from './clients.constants';

export const useClients = ({ page, size, search, advisorId }: ClientsQueryParams) => {
	const queryClient = useQueryClient();

	const query = useQuery({
		...clientsKeys.list({ page, size, advisorId })._ctx.search({ search }),
		placeholderData: keepPreviousData,
	});

	const { data, isPlaceholderData } = query || {};

	useEffect(() => {
		if (!data || isPlaceholderData) return;
		const { last: isLastPage } = data;

		if (!isLastPage) {
			const prefetchNextPageParams = {
				page: page ? page + 1 : DEFAULT_PAGE + 1,
				...(size && { size }),
				...(advisorId && { advisorId }),
			};

			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			queryClient.prefetchQuery({
				...clientsKeys.list(prefetchNextPageParams)._ctx.search({ search }),
				staleTime: 5000,
			});
		}
	}, [data, page, size, search, isPlaceholderData, queryClient, advisorId]);

	return query;
};
