import { createQueryKeys } from '@lukemorales/query-key-factory';
import { PaginationQueryParams, SearchQueryParams } from '@/app/api';
import { getAdvisors } from '@/app/api/advisors/advisors.api';

export const advisorsKeys = createQueryKeys('advisors', {
	list: ({ page, size }: PaginationQueryParams) => ({
		queryKey: [{ page, size }],
		queryFn: () => getAdvisors({ page, size }),
		contextQueries: {
			search: ({ search }: SearchQueryParams) => ({
				queryKey: [{ page, size, search }],
				queryFn: () => getAdvisors({ page, size, search }),
			}),
		},
	}),
});
