import { Paginated } from '../api.types';
import { httpClient } from '../http-client';
import {
	Client,
	ClientActivityLog,
	ClientSettings,
	ClientsQueryParams,
	ClientQueryParams,
	UpdateClientSettingsParams,
} from './clients.types';

export const getClients = async ({
	page,
	size,
	search,
	advisorId,
}: ClientsQueryParams): Promise<Paginated<Client>> => {
	const params = {
		...(page && { page }),
		...(size && { size }),
		...(search && { name: search }),
	};
	const route = advisorId ? `/admin/clients-by-advisor/${advisorId}` : '/clients';
	const { data } = await httpClient.get<Paginated<Client>>(route, { params });

	return data;
};

export const getClient = async ({ clientId }: ClientQueryParams): Promise<Client> => {
	const { data } = await httpClient.get<Client>(`/clients/${clientId}`);

	return data;
};

export const getClientSettings = async ({
	clientId,
}: ClientQueryParams): Promise<ClientSettings> => {
	const { data } = await httpClient.get<ClientSettings>(`/clients/${clientId}/settings`);

	return data;
};

export const updateClientSettings = async ({
	clientId,
	payload,
}: UpdateClientSettingsParams): Promise<ClientSettings> => {
	const { data } = await httpClient.put<ClientSettings>(
		`/clients/${clientId}/settings/save`,
		payload
	);

	return data;
};

export const getClientActivityLog = async ({
	clientId,
}: ClientQueryParams): Promise<ClientActivityLog[]> => {
	const { data } = await httpClient.get<ClientActivityLog[]>(`/clients/${clientId}/activity_log`);

	return data;
};
