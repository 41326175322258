import { useEffect, useState } from 'react';
import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import {
	ContentCopyOutlined as ContentCopyOutlinedIcon,
	LaunchOutlined as LaunchOutlinedIcon,
} from '@mui/icons-material';
import { Client, ClientPayloadData, ClientSettings } from '@/app/api/clients';
import { ButtonLink } from '@/components/ui/buttons';
import { FormDialog } from '@/components/ui/form-dialog';
import { copyLink } from '@/shared/utils';
import { SwitchContainer } from '../switch-container';
import { switchData } from '../../dialogues.constants';

interface DialogueSetupDialogProps {
	data: Client;
	settings: ClientSettings | undefined;
	open: boolean;
	isLoading?: boolean;
	onSubmit: (submitData: ClientPayloadData) => void;
	onClose: () => void;
}

const DialoguesSetupDialog: React.FC<DialogueSetupDialogProps> = ({
	data,
	open,
	isLoading,
	settings,
	onSubmit,
	onClose,
}): React.ReactElement => {
	const [selectedFlows, setSelectedFlows] = useState<string[] | undefined>([]);
	const [password, setPassword] = useState<string>('');
	const [payloadData, setPayloadData] = useState<ClientPayloadData>({
		flows: [],
		clientId: 0,
		auth: false,
		password: null,
	});
	const [updatedSwitchData, setUpdatedSwitchData] = useState(
		switchData.map((sw) => ({
			...sw,
			checked: !!settings?.flows.find((fl) => fl.flowName === sw.value),
		}))
	);

	useEffect(() => {
		setSelectedFlows(settings?.flows.map((fl) => fl.flowName));
		setPayloadData({
			flows: selectedFlows,
			clientId: data?.id,
			auth: false,
			password,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, settings]);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
		const flow = e.target.value;
		const flowIdx = updatedSwitchData.findIndex((d) => d.value === flow);
		updatedSwitchData[flowIdx].checked = checked;
		setUpdatedSwitchData(updatedSwitchData);

		if (checked) setSelectedFlows([...(selectedFlows || []), flow]);
		else setSelectedFlows(selectedFlows?.filter((fl) => fl !== flow));
	};

	useEffect(() => {
		setPayloadData({
			flows: selectedFlows,
			clientId: data?.id,
			auth: !!password,
			password,
		});
	}, [data?.id, password, selectedFlows]);

	const dialogHeader = (
		<Stack
			direction="column"
			gap="1rem"
			sx={{ borderBottom: '1px solid #e0e0e0', paddingBottom: '1.5rem', marginBottom: '1.5rem' }}
		>
			{/* <Typography component="p" sx={{ fontSize: '16px' }}>
				A link to the Dialogues experience will be emailed to <b>{data?.clientEmail}.</b>
			</Typography> */}
			<Box sx={{ display: 'flex' }}>
				<ButtonLink
					color="primary"
					variant="outlined"
					endIcon={<LaunchOutlinedIcon />}
					to={data?.clientUrl}
					target="_blank"
					rel="noopener noreferrer"
				>
					Open client portal
				</ButtonLink>
				<Button
					color="primary"
					variant="outlined"
					className="no-print"
					endIcon={<ContentCopyOutlinedIcon />}
					sx={{ marginLeft: '1rem' }}
					onClick={() => copyLink(data?.clientUrl)}
				>
					copy link
				</Button>
			</Box>
		</Stack>
	);

	return (
		<FormDialog
			open={open}
			onClose={onClose}
			title="Dialogues Setup"
			onSubmit={() => onSubmit(payloadData)}
			description={dialogHeader}
			actions={{
				cancel: {
					label: 'Cancel',
				},
				confirm: {
					label: 'Save',
					isLoading,
				},
			}}
		>
			<Stack direction="column" gap="1.5rem">
				<Box>
					<Typography component="h4" variant="h5" sx={{ fontWeight: '500' }}>
						Dialogues Settings
					</Typography>
					<Typography
						component="p"
						sx={{ fontSize: '14px', color: '#000000', opacity: '0.6', marginTop: '0' }}
					>
						Choose which sections will appear in the Client Portal.
					</Typography>
				</Box>
				<Box>
					{updatedSwitchData.map((sw) => (
						<SwitchContainer
							text={sw.label}
							value={sw.value}
							checked={sw.checked}
							key={sw.value}
							handleChange={(event, checked) => handleChange(event, checked)}
						/>
					))}
				</Box>
				<Box display="flex" flexDirection="column" gap="1.5rem">
					<Typography component="h4" variant="h5" sx={{ fontWeight: '500' }}>
						Additional Security
					</Typography>
					<TextField
						onChange={(event) => setPassword(event.target.value)}
						id="password"
						name="password"
						type="password"
						variant="filled"
						label="Add a Password"
						fullWidth
					/>
				</Box>
			</Stack>
		</FormDialog>
	);
};

export default DialoguesSetupDialog;
