import { FunctionComponent, ReactElement, useState } from 'react';
import { Outlet, useOutletContext, useParams } from 'react-router-dom';
import { Box, Paper, Stack } from '@mui/material';
import { Client, ClientPayloadData } from '@/app/api/clients';
import { TabsNav } from '@/components/ui/tabs';
import { Button } from '@/components/ui/buttons';
import { DialoguesEmptyState } from './components/empty-state';
import { DialoguesSetupDialog } from './components/dialogues-setup-dialog';
import { DialogueContext } from './dialogues.types';
import {
	useClientActivityLog,
	useClientSettings,
	useUpdateClientSettings,
} from '../client.queries';

const DialoguesPage: FunctionComponent = (): ReactElement => {
	const client = useOutletContext<Client>();

	const [open, setModalOpen] = useState<boolean>(false);

	const { id: clientId } = useParams();
	const { data: settings } = useClientSettings({ clientId });
	const { data: activityLog = [] } = useClientActivityLog({ clientId });

	const { mutate: updateClientSettings, isPending: isSubmitting } = useUpdateClientSettings();

	const onSubmit = (payload: ClientPayloadData) => {
		updateClientSettings(
			{ clientId: client.id, payload },
			{
				onSuccess: () => {
					setModalOpen(false);
				},
			}
		);
	};

	// eslint-disable-next-line react/jsx-no-useless-fragment
	if (!settings) return <></>;

	return (
		<>
			{!settings.initialSetup ? (
				<Paper sx={{ display: 'flex', justifyContent: 'center', padding: '3rem' }}>
					<DialoguesEmptyState
						title="Dialogues not started yet"
						text="Once you set up dialogues, activity log and setup will be displayed here."
						callToAction={
							<Button variant="contained" color="primary" onClick={() => setModalOpen(true)}>
								Dialogues Setup
							</Button>
						}
					/>
				</Paper>
			) : (
				<Paper sx={{ display: 'flex', padding: '1.5rem' }} className="paper-print">
					<Stack direction="column" width="100%">
						<Box
							sx={{ borderBottom: 1, borderColor: '#d9dbde', width: '100%' }}
							className="no-print"
						>
							<TabsNav
								ariaLabel="Dialogues navigation"
								tabs={[
									{
										label: 'Overview',
										route: '/clients/:id/dialogues/overview',
										to: 'overview',
									},
									{
										label: 'Fast Brain',
										route: '/clients/:id/dialogues/fast-brain',
										to: 'fast-brain',
									},
									{
										label: 'Slow Brain',
										route: '/clients/:id/dialogues/slow-brain',
										to: 'slow-brain',
									},
									{
										label: 'Risk Assessment Questionnaire',
										route: '/clients/:id/dialogues/risk-assessment-questionnaire',
										to: 'risk-assessment-questionnaire',
									},
								]}
							/>
						</Box>
						<Outlet context={{ settings, client, activityLog } satisfies DialogueContext} />
					</Stack>
				</Paper>
			)}
			<DialoguesSetupDialog
				data={client}
				settings={settings}
				open={open}
				isLoading={isSubmitting}
				onSubmit={(submitData) => onSubmit(submitData)}
				onClose={() => setModalOpen(false)}
			/>
		</>
	);
};
export default DialoguesPage;
