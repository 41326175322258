import { createQueryKeys } from '@lukemorales/query-key-factory';
import {
	getFastBrainAiOutput,
	getFastBrainTileQuestionsAnswers,
	getSlowBrainAiOutput,
	getSlowBrainTileQuestionsAnswers,
	getRiskAssessmentTileQuestionsAnswers,
	getRiskAssessmentAiOutput,
	getNextBestActionsAiOutput,
	getNextBestActionsTasks,
	getAssignees,
} from '@/app/api/flows';
import { AssigneesSearchParams } from '@/app/api/flows/next-best-actions/tasks.types';
import { PaginationCustomQueryParams } from '@/app/api/api.types';

export const flowKeys = createQueryKeys('flows', {
	fastBrainAnswers: (clientId: string | number) => ({
		queryKey: ['fast_brain', clientId],
		queryFn: () => getFastBrainTileQuestionsAnswers(clientId),
	}),
	fastBrainAiOutput: (clientId: string | number) => ({
		queryKey: ['fast_brain', clientId],
		queryFn: () => getFastBrainAiOutput(clientId),
	}),
	slowBrainAnswers: (clientId: string | number) => ({
		queryKey: ['slow_brain', clientId],
		queryFn: () => getSlowBrainTileQuestionsAnswers(clientId),
	}),
	slowBrainAiOutput: (clientId: string | number) => ({
		queryKey: ['slow_brain', clientId],
		queryFn: () => getSlowBrainAiOutput(clientId),
	}),
	riskAssessmentAnswers: (clientId: string | number) => ({
		queryKey: ['risk_assessment', clientId],
		queryFn: () => getRiskAssessmentTileQuestionsAnswers(clientId),
	}),
	riskAssessmentAiOutput: (clientId: string | number) => ({
		queryKey: ['risk_assessment', clientId],
		queryFn: () => getRiskAssessmentAiOutput(clientId),
	}),
	nextBestActionsAiOutput: (clientId: string | number) => ({
		queryKey: ['next_best_actions', clientId],
		queryFn: () => getNextBestActionsAiOutput(clientId),
	}),
	nextBestActionsTasks: (clientId: string | number) => ({
		queryKey: ['next_best_actions_table', clientId],
		queryFn: () => getNextBestActionsTasks(clientId),
	}),
	nextBestActionsTasksAssignees: ({
		size,
		page,
		advisorOffset,
		clientOffset,
		advisorErpId,
	}: PaginationCustomQueryParams) => ({
		queryKey: [
			'next_best_actions_table_assignees',
			{ size, page, advisorOffset, clientOffset, advisorErpId },
		],
		queryFn: () => getAssignees({ size, page, advisorOffset, clientOffset, advisorErpId }),
		contextQueries: {
			search: ({ name }: AssigneesSearchParams) => ({
				queryKey: [{ page, size, name, advisorOffset, clientOffset, advisorErpId }],
				queryFn: () =>
					getAssignees({ page, size, name, advisorOffset, clientOffset, advisorErpId }),
			}),
		},
	}),
});
