import { FunctionComponent, ReactElement, useState } from 'react';
import { Box, Paper, Stack } from '@mui/material';
import AdminPageLayout from '@/components/layouts/admin-page/admin-page.layout';
import { useSearchQuery } from '@/shared/hooks/use-search-query';
import { DEFAULT_PAGE } from './advisors.constsnts';
import { useAdvisors } from './advisors.queries';
import { SearchField } from '@/components/ui/inputs';
import { AdvisorsTable } from './advisors-table';

const AdvisorsPage: FunctionComponent = (): ReactElement => {
	const [page, setPage] = useState<number>(DEFAULT_PAGE);
	const [searchFilter, setSearchFilter] = useState<string>('');
	const search = useSearchQuery({
		query: searchFilter,
		onChange: () => setPage(DEFAULT_PAGE),
	});

	const { data } = useAdvisors({ page, search });

	return (
		<AdminPageLayout title="Advisors">
			<Box component={Paper} padding="1.5rem">
				<Stack direction="column" gap="1.5rem">
					<SearchField
						label="Search advisors"
						value={searchFilter}
						onChange={(event) => setSearchFilter(event.target.value)}
					/>
					<AdvisorsTable data={data} onPageChange={(page) => setPage(page)} />
				</Stack>
			</Box>
		</AdminPageLayout>
	);
};

export default AdvisorsPage;
