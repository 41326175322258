import { FunctionComponent, ReactElement, useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { SupervisorAccountRounded as ClientsIcon } from '@mui/icons-material';
import { Sidebar, SidebarNavItemProps } from '@/components/ui/sidebar';
import { getUserRole } from '@/shared/utils';
import { RoleTypes } from '@/app/api/role/role.types';

const PageLayout: FunctionComponent = (): ReactElement => {
	const { role } = getUserRole();
	const isRoleAdmin = useMemo(() => role === RoleTypes.admin, [role]);

	const sidebarItems: SidebarNavItemProps[] = [
		isRoleAdmin
			? { title: 'Advisors', icon: <ClientsIcon />, path: '/advisors' }
			: { title: 'Clients', icon: <ClientsIcon />, path: '/clients' },
	];

	return (
		<>
			<Sidebar items={sidebarItems} />
			<Outlet />
		</>
	);
};

export default PageLayout;
