import { RouteObject } from 'react-router-dom';
import { advisorRoutes } from './advisor.routes';
import AdvisorsPage from '@/advisors';
import SingleAdvisorGuard from './guards/single-advisor-guard';

export const adminRoutes: RouteObject[] = [
	{
		path: '',
		element: <AdvisorsPage />,
	},
	{
		path: ':advisorId/',
		element: <SingleAdvisorGuard />,
		children: [...advisorRoutes],
	},
];
