/* eslint-disable react-hooks/exhaustive-deps */
import { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { useOktaAuth } from '@okta/okta-react';
import { AUTH_KEY, getUserRole, ROLE_KEY } from '@/shared/utils';
import { getRole } from '@/app/api/role';
import Backdrop from '@/components/backdrop';

const AuthGuard: FunctionComponent = (): ReactElement => {
	const { authState, oktaAuth } = useOktaAuth();
	const [isLoading, setIsLoading] = useState<boolean>(true);

	const { mutate } = useMutation({
		mutationFn: () => getRole(),
		onSuccess: (data) => {
			localStorage.setItem(ROLE_KEY, JSON.stringify(data));
			setTimeout(() => {
				setIsLoading(false);
			}, 2500);
		},
	});

	useEffect(() => {
		if (oktaAuth && oktaAuth.getAccessToken()) {
			localStorage.setItem(AUTH_KEY, JSON.stringify(authState));

			if (!getUserRole().role) {
				mutate();
			} else {
				setIsLoading(false);
			}
		}
	}, [authState, oktaAuth]);

	if (!oktaAuth.getAccessToken()) {
		return <Navigate to="login" />;
	}

	if (isLoading) {
		return <Backdrop />;
	}

	return <Outlet />;
};

export default AuthGuard;
