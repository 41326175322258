import { FunctionComponent, ReactElement, ReactNode } from 'react';
import { Stack, Typography } from '@mui/material';
import { Breadcrumbs, BreadcrumbsItem } from '@/components/ui/breadcrumbs';
import styles from './admin-page.styles';

interface AdminPageLayoutProps {
	breadcrumbs?: BreadcrumbsItem[];
	title: ReactNode | string;
	children: ReactNode;
}

const AdminPageLayout: FunctionComponent<AdminPageLayoutProps> = ({
	breadcrumbs,
	title,
	children,
}): ReactElement => {
	const heading =
		typeof title === 'string' ? (
			<Typography component="h1" variant="h4">
				{title}
			</Typography>
		) : (
			title
		);

	return (
		<Stack
			direction="column"
			gap="1rem"
			component="main"
			role="main"
			sx={styles.advisor_page}
			className="main-print"
		>
			<Stack direction="column" gap="1rem">
				{breadcrumbs && <Breadcrumbs items={breadcrumbs} />}
				{heading}
			</Stack>
			{children}
		</Stack>
	);
};

export default AdminPageLayout;
