import { Paginated } from '../api.types';
import { httpClient } from '../http-client';
import { Advisor, AdvisorsQueryParams } from './advisors.types';

export const getAdvisors = async ({
	page,
	size,
	search,
}: AdvisorsQueryParams): Promise<Paginated<Advisor>> => {
	const params = {
		...(page && { page }),
		...(size && { size }),
		...(search && { name: search }),
	};

	const { data } = await httpClient.get<Paginated<Advisor>>('/admin/advisors', { params });

	return data;
};
