export enum RoleTypes {
	admin = 'admin',
	advisor = 'advisor',
}

export type RoleType = RoleTypes.admin | RoleTypes.advisor;

export interface Role {
	role: RoleType;
}
