import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Paginated } from '@/app/api/api.types';
import { Column, Pagination, Table } from '@/components/ui/table';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE, SELECTED_ADVISOR } from '../advisors.constsnts';
import { Advisor } from '@/app/api/advisors/advisors.types';

interface AdvisorsTableProps {
	data?: Paginated<Advisor>;
	onPageChange: (page: number) => void;
}

const AdvisorsTable: React.FC<AdvisorsTableProps> = ({
	data,
	onPageChange,
}): React.ReactElement => {
	const navigate = useNavigate();

	const {
		content: rows = [],
		totalElements: totalItems = 0,
		pageable: { pageNumber = DEFAULT_PAGE, pageSize = DEFAULT_PAGE_SIZE } = {},
	} = data || {};

	const columns: Column<Advisor>[] = [
		{ name: 'Advisor', accessors: ['advisorName'] },
		{ name: 'Email', accessors: ['advisorEmail'] },
	];
	const onRowClick = (advisor: Advisor) => {
		const { erpId } = advisor;
		localStorage.setItem(SELECTED_ADVISOR, JSON.stringify(advisor));
		navigate(`../advisors/${erpId}/clients`);
	};
	const pagination: Pagination = {
		count: totalItems,
		page: pageNumber,
		rowsPerPage: pageSize,
		onPageChange,
	};

	return (
		<Table<Advisor> columns={columns} rows={rows} onRowClick={onRowClick} pagination={pagination} />
	);
};

export default AdvisorsTable;
