import { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { Box, Divider, Paper, Stack, Typography } from '@mui/material';
import { Client } from '@/app/api/clients';
import { Button } from '@/components/ui/buttons';
import {
	useClientPortfolioConsiderations,
	usePortfolioConsiderations,
} from './portfolio-considerations.queries';
import { PortfoliosTable } from './portfolios-table';
import { PortfoliosDialog } from './portfolios-dialog';

const PortfolioConsiderationsPage: FunctionComponent = (): ReactElement => {
	const client = useOutletContext<Client>();

	const [isPortfoliosDialogOpen, setIsPortfoliosDialogOpen] = useState<boolean>(false);
	const [clientPortfoliosPage, setClientPortfoliosPage] = useState<number>(0);
	const [portfoliosPage, setPortfoliosPage] = useState<number>(0);

	useEffect(() => {
		if (isPortfoliosDialogOpen) setPortfoliosPage(0);
	}, [isPortfoliosDialogOpen]);

	const { data: clientPortfolios = [] } = useClientPortfolioConsiderations(client.id);
	const { data: portfolios } = usePortfolioConsiderations(client.id);

	return (
		<Box component={Paper} padding="1.5rem">
			<Stack direction="row" alignItems="center" justifyContent="space-between" gap="1rem">
				<Typography component="h2" variant="h5">
					Portfolio Considerations
				</Typography>

				<Button variant="contained" onClick={() => setIsPortfoliosDialogOpen(true)}>
					Manage
				</Button>
			</Stack>

			<Divider
				sx={{
					marginTop: '1.5rem',
					marginBottom: '2rem',
				}}
			/>

			<PortfoliosTable
				data={clientPortfolios}
				page={clientPortfoliosPage}
				onPageChange={(page) => setClientPortfoliosPage(page)}
			/>

			<PortfoliosDialog
				dialog={{
					open: isPortfoliosDialogOpen,
					onClose: () => setIsPortfoliosDialogOpen(false),
					onUpdate: () => {
						setClientPortfoliosPage(0);
						setIsPortfoliosDialogOpen(false);
					},
				}}
				table={{
					data: portfolios,
					page: portfoliosPage,
					onPageChange: (page) => setPortfoliosPage(page),
				}}
			/>
		</Box>
	);
};

export default PortfolioConsiderationsPage;
