/* eslint-disable indent */
import { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import {
	Box,
	Button,
	Stack,
	styled,
	TextField,
	ToggleButton,
	ToggleButtonGroup,
	Typography,
} from '@mui/material';
import {
	LaunchOutlined as LaunchOutlinedIcon,
	ContentCopyOutlined as ContentCopyOutlinedIcon,
} from '@mui/icons-material';
import { Client, ClientPayloadData, ClientSettings } from '@/app/api/clients';
import { copyLink } from '@/shared/utils';
import { ButtonLink } from '@/components/ui/buttons';
import { useUpdateClientSettings } from '@/client/client.queries';
import { SwitchContainer } from '../components/switch-container';
import { switchData } from '../dialogues.constants';
import { GuidingType, GuidingTypes } from '@/app/api/clients/clients.types';

export interface DialogueSetupProps {
	settings: ClientSettings;
	client: Client;
}

const RestButton = styled(Button)({
	textDecoration: 'underline',
	textTransform: 'capitalize',
	'&:hover': {
		backgroundColor: 'transparent',
		textDecoration: 'underline',
	},
	'&:active': {
		backgroundColor: 'transparent',
		textDecoration: 'underline',
	},
});

const ToggleButtonSx = {
	borderColor: '#2F9BCF',
	color: '#2F9BCF',
	backgroundColor: 'white',
	'&.Mui-selected': {
		backgroundColor: '#2F9BCF',
		color: 'white',
		'&:hover': {
			backgroundColor: '#2F9BCF',
		},
	},
};

const DialogueSetup: FunctionComponent<DialogueSetupProps> = ({
	client,
	settings,
}: DialogueSetupProps): ReactElement => {
	const [dialogueGuidedBy, setDialogueGuidedBy] = useState<GuidingType>(
		settings.additionalSettings.dialogueGuidedBy || GuidingTypes.ADVISOR
	);
	const [resetPass, setResetPass] = useState<boolean>(false);
	const [saveDisabled, setSaveDisabled] = useState<boolean>(true);
	const [selectedFlows, setSelectedFlows] = useState<string[] | undefined>([]);
	const [password, setPassword] = useState<string | null>(null);
	const [updatedSwitchData, setUpdatedSwitchData] = useState(
		switchData.map((sw) => ({
			...sw,
			checked: !!settings.flows.find((fl) => fl.flowName === sw.value),
		}))
	);
	const [payloadData, setPayloadData] = useState<ClientPayloadData>({
		flows: [],
		clientId: 0,
		auth: false,
		password: null,
		dialogueGuidedBy,
	});

	useEffect(() => {
		setSelectedFlows(settings?.flows.map((fl) => fl.flowName));
		setPayloadData({
			flows: selectedFlows,
			clientId: client?.id,
			auth: false,
			password,
			dialogueGuidedBy,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [client, settings]);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
		const flow = e.target.value;
		const flowIdx = updatedSwitchData.findIndex((d) => d.value === flow);
		updatedSwitchData[flowIdx].checked = checked;
		setUpdatedSwitchData(updatedSwitchData);

		if (checked) setSelectedFlows([...(selectedFlows || []), flow]);
		else setSelectedFlows(selectedFlows?.filter((fl) => fl !== flow));
	};

	const handleGuiding = (newValue: GuidingType) => {
		if (newValue === null) return;
		setDialogueGuidedBy(newValue);
		setSaveDisabled(false);
	};

	useEffect(() => {
		setPayloadData({
			flows: selectedFlows,
			clientId: client?.id,
			...(password || resetPass
				? {
						auth:
							settings.additionalSettings?.auth && password
								? settings.additionalSettings?.auth
								: !!password,
					}
				: {}),
			password,
			dialogueGuidedBy,
		});
	}, [
		client?.id,
		password,
		selectedFlows,
		settings.additionalSettings?.auth,
		resetPass,
		dialogueGuidedBy,
	]);

	const { mutate: updateClientSettings, isPending: isSubmitting } = useUpdateClientSettings();

	const saveChanges = () => {
		updateClientSettings(
			{ clientId: client.id, payload: payloadData },
			{
				onSuccess: () => {
					setResetPass(false);
					setSaveDisabled(true);
				},
			}
		);
	};

	return (
		<Box sx={{ flex: '1 1 100%', paddingRight: '1.5rem', borderRight: '1px solid #e0e0e0' }}>
			<Stack direction="column" gap="1.5rem">
				<Box sx={{ borderBottom: '1px solid #e0e0e0', paddingBottom: '1.5rem' }}>
					<Typography component="h3" variant="h6" sx={{ fontWeight: '500' }}>
						Dialogues Setup
					</Typography>
				</Box>
				<Box
					sx={{
						borderBottom: '1px solid #e0e0e0',
						paddingBottom: '1.5rem',
					}}
				>
					<Box sx={{ paddingBottom: '1.5rem' }}>
						<Typography component="p" sx={{ fontSize: '16px' }}>
							Access <b>{client?.clientName}`s</b> Dialogues experience below.
						</Typography>
						<Box sx={{ display: 'flex', marginTop: '1rem' }}>
							<ButtonLink
								color="primary"
								variant="outlined"
								endIcon={<LaunchOutlinedIcon />}
								to={client?.clientUrl}
								target="_blank"
								rel="noopener noreferrer"
							>
								Open client portal
							</ButtonLink>
							<Button
								color="primary"
								variant="outlined"
								className="no-print"
								endIcon={<ContentCopyOutlinedIcon />}
								sx={{ marginLeft: '1rem' }}
								onClick={() => copyLink(client?.clientUrl)}
							>
								copy link
							</Button>
						</Box>
					</Box>
					<Box display="flex" flexDirection="column" gap="1.5rem">
						<Box>
							<Typography component="h3" variant="h6" sx={{ fontWeight: '500' }}>
								Dialogues Settings
							</Typography>
							<Typography component="p" sx={{ fontSize: '14px', color: '#000000', opacity: '0.6' }}>
								Choose which sections will appear in the Client Portal.
							</Typography>
						</Box>
						<Box>
							{updatedSwitchData.map((sw) => (
								<SwitchContainer
									text={sw.label}
									value={sw.value}
									checked={sw.checked}
									key={sw.value}
									handleChange={(event, checked) => {
										handleChange(event, checked);
										setSaveDisabled(false);
									}}
								/>
							))}
						</Box>
					</Box>
					<Box
						display="flex"
						justifyContent="space-between"
						alignItems="center"
						borderBottom="1px solid #e0e0e0"
						paddingBottom="12px"
						paddingTop="12px"
					>
						<Typography component="label" htmlFor="toggle" sx={{ fontSize: '16px' }}>
							Who will be guiding the Dialogues?
						</Typography>
						<ToggleButtonGroup
							color="primary"
							value={dialogueGuidedBy}
							sx={{ height: '36px' }}
							onChange={(_, newValue: GuidingType) => {
								handleGuiding(newValue);
							}}
							exclusive
							aria-label="Guiding the Dialogues"
						>
							<ToggleButton value={GuidingTypes.ADVISOR} aria-label="advisor" sx={ToggleButtonSx}>
								{GuidingTypes.ADVISOR}
							</ToggleButton>
							<ToggleButton value={GuidingTypes.CLIENT} aria-label="client" sx={ToggleButtonSx}>
								{GuidingTypes.CLIENT}
							</ToggleButton>
						</ToggleButtonGroup>
					</Box>
					<Box display="flex" flexDirection="column" gap="1rem" sx={{ marginTop: '1.5rem' }}>
						<Typography component="h3" variant="h6" sx={{ fontWeight: '500' }}>
							Additional Security
						</Typography>
						{settings?.additionalSettings?.auth && !resetPass && (
							<Box display="flex" alignItems="center">
								<Typography component="p">Password added</Typography>
								<RestButton
									color="primary"
									variant="text"
									onClick={() => {
										setResetPass(true);
										setSaveDisabled(false);
										setPassword(null);
									}}
								>
									Reset
								</RestButton>
							</Box>
						)}
						{(!settings?.additionalSettings?.auth || resetPass) && (
							<TextField
								id="name"
								name="name"
								variant="filled"
								type="password"
								label="Add a Password"
								onChange={(event) => {
									setPassword(event.target.value);
									setSaveDisabled(false);
								}}
								fullWidth
							/>
						)}
					</Box>
				</Box>
				<Box>
					<Button
						color="primary"
						variant="contained"
						onClick={() => saveChanges()}
						disabled={saveDisabled || isSubmitting}
					>
						Save changes
					</Button>
				</Box>
			</Stack>
		</Box>
	);
};

export default DialogueSetup;
