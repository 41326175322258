import React from 'react';
import { NavLink, NavLinkProps, useParams } from 'react-router-dom';
import {
	ToggleButton as MuiToggleButton,
	ToggleButtonProps as MuiToggleButtonProps,
	ToggleButtonGroup as MuiToggleButtonGroup,
	styled,
} from '@mui/material';
import { useCurrentTab } from '@/shared/hooks/use-current-tab';
import { CommonTabsProps } from '../tabs.types';

const ToggleButton = styled(MuiToggleButton)<MuiToggleButtonProps & NavLinkProps>(() => ({
	'&.Mui-selected': {
		fontWeight: 700,
	},
}));

const ToggleGroupNav: React.FC<CommonTabsProps> = ({ tabs, ariaLabel }): React.ReactElement => {
	const { advisorId } = useParams();
	const routes = tabs.map(({ route }) => (advisorId ? `/advisors/:advisorId${route}` : route));
	const currentTab = useCurrentTab(routes) || routes[0];

	return (
		<MuiToggleButtonGroup
			aria-label={ariaLabel}
			value={currentTab}
			exclusive
			color="primary"
			size="small"
			className="no-print"
		>
			{tabs.map(({ to, label, route }) => (
				<ToggleButton
					component={NavLink}
					key={to}
					to={to}
					value={advisorId ? `/advisors/:advisorId${route}` : route}
				>
					{label}
				</ToggleButton>
			))}
		</MuiToggleButtonGroup>
	);
};

export default ToggleGroupNav;
