import { FunctionComponent, ReactElement } from 'react';
import { Outlet, useOutletContext, useParams } from 'react-router-dom';
import { Box, Button, Paper, Stack } from '@mui/material';
import { PrintOutlined as Print } from '@mui/icons-material';
import { TabsNav } from '@/components/ui/tabs';
import { DialogueContext } from '../dialogues/dialogues.types';
import { useCurrentTab } from '@/shared/hooks/use-current-tab';

const NextBestActionsPage: FunctionComponent = (): ReactElement => {
	const data = useOutletContext<DialogueContext>();
	const { advisorId } = useParams();

	const tabs = [
		{
			label: 'Next best actions tasks',
			route: '/clients/:id/next-best-actions/tasks',
			to: '',
		},
		{
			label: 'AI Output',
			route: '/clients/:id/next-best-actions/ai-output',
			to: 'ai-output',
		},
	];

	const routes = tabs.map(({ route }) => (advisorId ? `/advisors/:advisorId${route}` : route));
	const currentTab = useCurrentTab(routes) || routes[0];

	return (
		<Paper sx={{ display: 'flex', padding: '1.5rem' }}>
			<Stack direction="column" width="100%">
				<Box sx={{ borderBottom: 1, borderColor: '#d9dbde', width: '100%' }} className="no-print">
					<TabsNav ariaLabel="Dialogues navigation" tabs={tabs} />
				</Box>
				{currentTab.includes(tabs[1].route) && (
					<Stack direction="row" justifyContent="end" my="2rem">
						<Stack direction="row" gap="1rem">
							<Button
								variant="outlined"
								endIcon={<Print />}
								onClick={() => window.print()}
								className="no-print"
							>
								Save as PDF
							</Button>
						</Stack>
					</Stack>
				)}
				<Outlet context={data} />
			</Stack>
		</Paper>
	);
};

export default NextBestActionsPage;
